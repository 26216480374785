<template>
  <div class="footer">
    <div v-for="(menu, index) in menuList" v-bind:key="index" class="footer_item" :style="isThisPathColor(menu)"
         @click="jump(menu.target)">
      <div style="height: 40px">
        <!--        <i :class="menu.icon" class="main_icon"></i>-->
        <img style="height: 100%" :src="require('../assets/foot/' + menu.image)" alt="">
      </div>
      <div class="main_title">
        {{ getLang(menu.title) }}
      </div>
    </div>
  </div>
</template>

<script>
import {getLang} from "@/common/language";

export default {
  props: {
    menuList: {
      type: Array,
      required: true
    }
  },
  name: 'Footer',
  data() {
    return {}
  },
  methods: {
    getLang,
    jump(url) {
      if (this.$route.path === url) {
        console.log(url)
        location.reload()
      } else {
        this.$router.push(url)
      }
    },
    isThisPathColor(menu) {
      return {
        color: this.$route.path === menu.target ? '#2599f8' : menu.child.includes(this.$route.path) ? '#2599f8' : '#93a2b1'
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.footer {
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  background: white;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
}

.footer_item {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_icon {
  font-size: 24px;
}

.main_title {
  font-size: 10px;
}

.is_this_yes {

}

.is_this_yes {

}

/*.clear_link {*/
/*  color: unset;*/
/*  cursor: unset;*/
/*  text-decoration: unset;*/
/*}*/
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import {projectDev} from "@/util/constant";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '当前是模板状态',
    theme: {
      bgColor: '#6681b9',
      activeTextColor: '#160B56',
      textColor: '#ffffff',
    },
    system: {
      language: 'en',
      loading: true,
    },
    user: {
      userId: undefined,
      userName: undefined,
      userAvatar: undefined,
      token: undefined,
      referralCode: undefined,
      realBalance:0,
    },
    eadCode: "",
    projectStatus: projectDev,
    test: ""
  },
  getters: {
    getLanguage: state => state.system.language,
    getTitle: state => state.title,
    getTheme: state => state.theme,
    getUser: state => state.user,
    getLoading: state => state.system.loading,
    getEadCode: state => state.eadCode,
    getProjectStatus: state => state.projectStatus,
  },
  mutations: {
    setLanguage(state, language) {
      // locale.use(elementLanguage[language])
      state.system.language = language
    },
    setLoading(state) {
      state.system.loading = true
    },
    cancelLoading(state) {
      state.system.loading = false
    },
    setUser(state, user) {
      state.user = user
    },
    setWithdrawPasswordStatus(state, withdrawPasswordStatus) {
      state.user.withdrawPasswordStatus = withdrawPasswordStatus
    },
    setBaseInfo(state, dto){
      state.user.realBalance=dto.realBalance;
      state.user.accountId=dto.accountId;
      state.user.referralCode=dto.referralCode;
    },
    setRealBalance(state, balance) {
      state.user.realBalance = balance
    },
    loginOut(state) {
      state.user = {
        userId: undefined,
        userName: undefined,
        token: undefined,
        referralCode:undefined,
      }
    },
    setEadCode(state, eadCode) {
      state.eadCode = eadCode
    },
    setTest(state, test) {
      state.test = test
    }
  },
  actions: {},
  modules: {}
})

<template>
  <div>
    <h2>语音通话</h2>
    <button @click="connectWS">ConnectWS</button>
    <button @click="getAllSessionId">getAllSessionId</button>
    <audio ref="remoteAudio" autoplay playsinline></audio>
    <div>
      我的 session id ：{{ mySessionId }}
    </div>
    <div>
      所有的ws
      <div v-for="(id, index) in allSessionId" v-bind:key="index">
        {{ id }}
        <button v-if="id !== mySessionId" @click="sendTestMessage(id)">发送测试消息</button>
        <button v-if="id !== mySessionId" @click="startCall(id)">Start Call</button>
      </div>
    </div>
    <div>
      我的消息
      <div v-for="(message, index) in myMessage" v-bind:key="index">
        {{ message }}
      </div>
    </div>
  </div>
</template>
<script>
import SimplePeer from 'simple-peer'
import {get} from "@/api/axios";
import {chat} from "@/api/api";

export default {
  name: 'call',
  computed: {},
  data() {
    return {
      peer: null,
      socket: null,
      mySessionId: '未连接ws',
      allSessionId: [],
      myMessage: []
    }
  },
  mounted() {
    this.$emit('setTitle', '语音通话')
  },
  methods: {
    startCall(id) {
      const remoteAudio = this.$refs['remoteAudio'];
      navigator.mediaDevices.getUserMedia({video: false, audio: true})
          .then(stream => {
            this.peer = new SimplePeer({
              initiator: true,
              trickle: false,
              stream: stream
            });
            this.setupPeer(this.peer, remoteAudio, id);
          })
          .catch(err => {
            console.error('Error accessing media devices:', err);
          });
    },
    sendTestMessage(id) {
      this.socket.send(JSON.stringify(
          {'type': 'message', 'to': id, 'from': this.mySessionId, 'message': this.mySessionId}
      ))
    },
    connectWS() {
      this.socket = new WebSocket('wss://192.168.3.60:8200/api/ws')
      this.socket.onmessage = message => {
        const data = JSON.parse(message.data);
        this.myMessage.push(data)
        if (data.type === 'session-id') {
          this.mySessionId = data.message;
          console.log('Received session ID:', this.mySessionId);
        } else if (data.type === 'peer') {
          const p_data = JSON.parse(data.message);
          if (this.peer === null) {
            this.startCall(data.from)
          }
          if (p_data.type === 'offer' || p_data.type === 'answer' || p_data.candidate) {
            this.peer.signal(p_data);
          }
        }
      };
    },
    getAllSessionId() {
      get(chat.getCallSession, {}, (res) => {
        this.allSessionId = res
      })
    },
    setupPeer(peer, remoteAudio, id) {
      peer.on('signal', data => {
        this.socket.send(JSON.stringify({
          'type': 'peer',
          'to': id,
          'from': this.mySessionId,
          'message': JSON.stringify(data)
        }))
      })
      peer.on('stream', stream => {
        remoteAudio.srcObject = stream
      })
      peer.on('error', err => console.error(err))
    },
  },
  beforeDestroy() {

  }
}

</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
}

.message-line-box {
  margin: 15px 5px 5px;
  position: relative;
}

.message-right {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.message-right .message-sender {
  text-align: right;
}

.message-time {
  position: absolute;
}

.message-left {
  display: flex;
}

.message-sender {
  margin: 0 5px;
  font-weight: bold;
  width: 25px;
  height: 25px;
}

.message-box {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
}

.message {
  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.chat-input {
  display: flex;
  align-items: center;
}

.chat-input input {
  flex: 1;
  margin-right: 5px;
}

.chat-input button {
  padding: 5px 10px;
  cursor: pointer;
}

.message-image img {
  max-width: 100%;
}

.upload-demo >>> .el-upload-list {
  overflow-x: auto;
}
</style>
<template>
  <div class="header">
    <div class="title">
      {{ title }}
    </div>
    <div class="back_icon" @click="$router.back()">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div v-if="button !== undefined" class="button" @click="$router.back()">
      <el-button @click="button.fc">{{ button.test }}</el-button>
    </div>
    <div v-if="menu !== undefined" class="menu" @click="$router.back()">
<!--      <i class="el-icon-arrow-left"></i>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ['title', 'button', 'menu'],
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
}
.title {
  padding: 20px;
  font-size: 20px;
}
.back_icon {
  position: absolute;
  left: 0;
  padding: 10px;
  font-size: 35px;
}
.button {
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 35px;
}
.menu {
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 35px;
}
</style>
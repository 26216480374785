import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios';
import VueClipBoard from 'vue-clipboard2';
import {DatetimePicker} from 'vant';
import WebSocketService from './util/websocket';

Vue.use(VueClipBoard);
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(DatetimePicker);
Vue.prototype.$http = axios

const websocketService = WebSocketService.Instance;
websocketService.init(store)
// 获取当前域名并构造 WebSocket URL
// const hostname = window.location.hostname;
// const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
// const port = window.location.port ? `:${window.location.port}` : '';
// const websocketUrl = `${protocol}//${hostname}${port}/ws`;
// websocketService.connect('ws://192.168.3.200:8090/ws');
Vue.prototype.$websocket = websocketService;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="root">
    <div class="line-div" style="display:flex;justify-content: space-between;align-items: center;">
      <el-avatar :size="80"
                 :src="file.get + $store.getters.getUser.userAvatar + '?X-T=' + $store.getters.getUser.token"></el-avatar>
      <div>
        <el-button size="small" type="primary" @click="changeAvatar">点击更换</el-button>
      </div>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <el-dialog :visible.sync="showChange" :show-close="false">
      <div style="background: white;padding: 15px">
        <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :multiple="false"
            :http-request="upload">
          <img v-if="imageUrl" :src="file.get + imageUrl + '?X-T=' + $store.getters.getUser.token" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div style="text-align: center">
          <el-button size="small" @click="showChange = false">取消</el-button>
          <el-button size="small" v-if="imageUrl" @click="changeMyAvatar">确定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {getLang} from "@/common/language";
import {file, my} from "@/api/api";
import axios from "axios";
import {Message} from "element-ui";
import {post} from "@/api/axios";

export default {
  name: 'MyProfile',
  computed: {
    file() {
      return file
    }
  },
  data() {
    return {
      imageUrl: '',
      showChange: false,
      imageTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml', 'image/bmp', 'image/tiff']
    }
  },
  created() {
    // this.load()
  },
  mounted() {
    this.init()
  },
  methods: {
    getLang,
    init() {
      this.$emit('setTitle', '我的信息')
    },
    changeAvatar() {
      this.showChange = true
    },
    changeMyAvatar() {
      post(my.changeMyAvatar, {avatar: this.imageUrl}, () => {
        this.showChange = false
      })
    },
    handleAvatarSuccess(res, file) {
      console.log(file)
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    upload(data) {
      console.log(data)
      let formData = new FormData();
      formData.append("file", data.file);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-T': this.$store.getters.getUser.token
        }
      }
      let that = this
      axios.post(file.up, formData, config).then(function (response) {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // if (response.data.message !== '') {
            //   Message.success(response.data.message)
            // }
            that.imageUrl = response.data.data
            data.onSuccess(response, data.file, that.filesList)
          } else {
            // this.files = this.files.filter(item => item.uid !== data.file.uid);
            if (response.data.message !== '') {
              Message.error(response.data.message)
            }
            data.onError(response, data.file, that.filesList)
          }
        } else {
          data.onError(response, data.file, that.filesList)
        }
      })
    },
    beforeAvatarUpload(file) {
      if (this.isImage(file.type)) {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 10MB!');
          return false;
        }
        return true;
      } else {
        this.$message.error('上传头像只能使用图片!');
        return false;
      }
    },
    isImage(fileType) {
      return this.imageTypes.includes(fileType);
    }
  }
}
</script>

<style scoped>

.root >>> .el-dialog {
  background: unset !important;
  width: unset !important;
}

.root >>> .el-dialog__header {
  padding: 0 !important;
}

.root >>> .el-dialog__body {
  padding: 0 !important;
}

.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader >>> .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>

<style scoped lang="scss">

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 15px;

  .line-div {
    border-bottom: 1px solid #92999f;
    padding: 15px 0;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 16px;
    background-color: #F5F5F5;
  }

  /*定义滚动条轨道
   内阴影+圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(240, 246, 251);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.27);
    background: transparent;
  }
}
</style>

<template>
  <div id="app">
    <router-view v-if="pass"/>
    <div v-if="$websocket.call.isCall"
         style="position: fixed;background: #004ba2;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%;display:flex;flex-direction: column;align-items: center;">
      <div style="position: absolute;top: 30%;width: 80px;text-align: center">
        <el-avatar :size="80"></el-avatar>
        <div>名称</div>
        <div>{{ $websocket.call.callId }}</div>
      </div>
      <div style="display:flex;position: absolute;bottom: 10%">
        <div style="width: 60px;text-align: center">
          <el-avatar></el-avatar>
          <div>麦克风</div>
        </div>
        <div style="width: 60px;text-align: center" @click="$websocket.cancelCall()">
          <el-avatar></el-avatar>
          <div>挂断</div>
        </div>
        <div style="width: 60px;text-align: center">
          <el-avatar></el-avatar>
          <div>扬声器</div>
        </div>
      </div>
    </div>
    <div v-if="$websocket.call.getCall"
         style="position: fixed;background: #004ba2;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%;display:flex;flex-direction: column;align-items: center;">
      <div style="position: absolute;top: 30%;width: 80px;text-align: center">
        <el-avatar :size="80"></el-avatar>
        <div>名称</div>
        <div>{{ $websocket.call.callId }}</div>
      </div>
      <div style="display:flex;position: absolute;bottom: 10%" @click="startCall">
        <div style="width: 60px;text-align: center">
          <el-avatar></el-avatar>
          <div>接通</div>
        </div>
        <div style="width: 60px;text-align: center" @click="$websocket.cancelCall()">
          <el-avatar></el-avatar>
          <div>挂断</div>
        </div>
      </div>
    </div>
    <div v-if="$websocket.call.calling"
         style="position: fixed;background: #004ba2;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%;display:flex;flex-direction: column;align-items: center;">
      <div style="position: absolute;top: 30%;width: 80px;text-align: center">
        <el-avatar :size="80"></el-avatar>
        <div>名称</div>
        <div>{{ $websocket.call.callId }}</div>
        <div>calling</div>
      </div>
      <div style="display:flex;position: absolute;bottom: 10%">
        <div style="width: 60px;text-align: center">
          <el-avatar></el-avatar>
          <div>麦克风</div>
        </div>
        <div style="width: 60px;text-align: center" @click="$websocket.cancelCall()">
          <el-avatar></el-avatar>
          <div>挂断</div>
        </div>
        <div style="width: 60px;text-align: center">
          <el-avatar></el-avatar>
          <div>扬声器</div>
        </div>
      </div>
    </div>
    <div v-if="$store.getters.getUser.userName === 'abc123321' || $store.getters.getUser.userName === 'guijing'" style="pointer-events: none;position: fixed;background: rgba(255,255,255,0);top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%;display:flex;flex-direction: column;align-items: center;">
      <div style="position: absolute;left: 1px;top: 20%">
        <div>ws: {{ $websocket.wss.status }}</div>
        <div>isCall: {{ $websocket.call.isCall }}</div>
        <div>getCall: {{ $websocket.call.getCall }}</div>
        <div>calling: {{ $websocket.call.calling }}</div>
        <div>peer_status: {{ $websocket.peer_obs.status }}</div>
      </div>
    </div>
    <audio ref="remoteAudio" autoplay playsinline></audio>
  </div>
</template>
<script>
import {checkLogin} from "@/router";
import {getLang} from "@/common/language";

export default {
  name: 'App',
  data() {
    return {
      pass: false,
      watchCallInterval: null
    }
  },
  methods: {
    startCall() {
      const remoteAudio = this.$refs['remoteAudio'];
      this.$websocket.answerCall()
      this.$websocket.startCall(remoteAudio)
    },
  },
  created() {
    if (checkLogin.includes(this.$route.path)) {
      if (this.$store.getters.getUser.token === undefined) {
        this.$message.error(getLang("you are not login yet"))
        this.$router.push('/user/login').catch(() => {
        })
        return false
      }
    }
    this.pass = true
  },
  mounted() {
    this.$websocket.connect()
  },
  beforeDestroy() {
    // 组件销毁前停止定时任务，避免内存泄漏
    this.$websocket.close()
  },
}
</script>
<style>

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

div {
  white-space: nowrap;
}

#app {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="cn">
    <!--    <Header/>-->
    <div class="body">
      <div class="content">
        <router-view/>
      </div>
    </div>
    <Footer :menu-list="menuList"/>
  </div>
</template>
<script>

import Footer from "@/components/Footer.vue";

export default {
  name: 'ContainerChristmas',
  data() {
    return {
      store: {},
      menuList: [
      ]
    }
  },
  comments: {},
  components: {
    Footer
  },
  mounted() {
    this.$store.commit('cancelLoading')
  },
  methods: {}
}
</script>
<style>
.el-dialog {
  max-width: 340px;
}

.el-dialog__wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-dialog--center {
  max-height: 90%;
  margin: unset !important;
  display: flex;
  flex-direction: column;
}

.el-dialog__body {
  flex: 1;
  overflow: hidden;
}

</style>
<style scoped>
.cn {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.body {
  min-height: 0;
  flex: 1;
  display: flex;
  margin-bottom: 60px;
}

.content {
  background: #f2f8fd;
  flex: 1;
  width: auto;
  overflow-y: auto;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}


</style>

<template>
  <div class="video-call">
    <div class="video-container-local">
      <video ref="localVideo" autoplay playsinline muted></video>
    </div>
    <div class="video-container-remote">
      <video ref="remoteVideo" autoplay playsinline></video>
    </div>
    <button @click="connectWS">ConnectWS</button>
    <button @click="getAllSessionId">getAllSessionId</button>
    <div>
      我的 session id ：
    </div>
    <div>
      {{ mySessionId }}
    </div>
    <div class="session-list">
      所有的ws
      <div v-for="(id, index) in allSessionId" :key="index" class="session-item">
        {{ id }}
        <button v-if="id !== mySessionId" @click="sendTestMessage(id)">发送测试消息</button>
        <button v-if="id !== mySessionId" @click="startCall(id)">Start Call</button>
      </div>
    </div>
    <div>
      我的消息
      <div v-for="(message, index) in myMessage" :key="index" class="message">
        {{ message }}
      </div>
    </div>
    <div>
      错误消息
      <div v-for="(message, index) in errorMessage" :key="index" class="error-message">
        {{ message }}
      </div>
    </div>
  </div>
</template>
<script>
import SimplePeer from 'simple-peer'
import {get} from "@/api/axios";
import {chat} from "@/api/api";

export default {
  name: 'call',
  computed: {},
  data() {
    return {
      peer: null,
      socket: null,
      mySessionId: '未连接ws',
      allSessionId: [],
      myMessage: [],
      errorMessage: [],
    }
  },
  mounted() {
    this.$emit('setTitle', '视频通话')
  },
  methods: {
    startCall(id) {
      const remoteVideo = this.$refs['remoteVideo'];
      const localVideo = this.$refs['localVideo'];
      navigator.mediaDevices.getUserMedia({video: true, audio: true})
          .then(stream => {
            this.errorMessage.push(stream.getAudioTracks().length)
            this.peer = new SimplePeer({
              initiator: true,
              trickle: false,
              stream: stream
            });
            localVideo.srcObject = stream;
            this.setupPeer(this.peer, remoteVideo, id);
          })
          .catch(err => {
            console.error('Error accessing media devices:', err);
          });
    },
    sendTestMessage(id) {
      this.socket.send(JSON.stringify(
          {'type': 'message', 'to': id, 'from': this.mySessionId, 'message': this.mySessionId}
      ))
    },
    connectWS() {
      this.socket = new WebSocket('wss://192.168.3.60:8200/api/ws')
      this.socket.onclose = event => {
        this.errorMessage.push('WebSocket closed:' + event)
      };

      this.socket.onerror = error => {
        this.errorMessage.push('WebSocket error:' + error)
      };
      this.startHeartbeat()
      this.socket.onmessage = message => {
        const data = JSON.parse(message.data);
        this.myMessage.push(data)
        if (data.type === 'peer') {
          const p_data = JSON.parse(data.message);
          if (this.peer === null) {
            this.startCall(data.from)
          }
          if (p_data.type === 'offer' || p_data.type === 'answer' || p_data.candidate) {
            this.peer.signal(p_data);
          }
        }
      };
    },
    startHeartbeat() {
      setInterval(() => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({type: 'heartbeat', message: 'ping'}));
        }
      }, 1000);
    },
    getAllSessionId() {
      get(chat.getCallSession, {}, (res) => {
        this.allSessionId = res
      })
    },
    setupPeer(peer, remoteVideo, id) {
      peer.on('signal', data => {
        let sendData = JSON.stringify({
          'type': 'peer',
          'to': id,
          'from': this.mySessionId,
          'message': JSON.stringify(data)
        })
        this.errorMessage.push(sendData)
        this.socket.send(sendData)
      })
      peer.on('connect', () => {
        this.errorMessage.push('连接成功')
      })
      peer.on('stream', stream => {
        remoteVideo.srcObject = stream
      })
      peer.on('error', err => this.errorMessage.push(err))
    },
  },
  beforeDestroy() {

  }
}

</script>

<style scoped>
.video-call {
  max-width: 430px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.video-container-remote {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 5px;
  width: calc(100% - 10px);
  height: 100%;
}

.video-container-remote video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持视频的宽高比并覆盖整个容器 */
}

.video-container-local {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 5px;
  width: 30%;
  height: 30%;
  position: fixed;
  right: 18px;
  bottom: 5px;
}

.video-container-local video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持视频的宽高比并覆盖整个容器 */
}

.button-container {
  margin-top: 10px;
}

.session-list {
  margin-top: 10px;
}

.session-item {
  margin-bottom: 5px;
  white-space: pre-wrap; /* 允许保留空白字符，并在必要时换行 */
  overflow-wrap: break-word; /* 在长单词或 URL 处进行断行 */
}

.message {
  margin-top: 5px;
  background-color: #f0f0f0;
  padding: 5px;
  white-space: pre-wrap; /* 允许保留空白字符，并在必要时换行 */
  overflow-wrap: break-word; /* 在长单词或 URL 处进行断行 */
}

.error-message {
  margin-top: 5px;
  background-color: #ffe6e6;
  padding: 5px;
  white-space: pre-wrap; /* 允许保留空白字符，并在必要时换行 */
  overflow-wrap: break-word; /* 在长单词或 URL 处进行断行 */
}
</style>
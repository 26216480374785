<template>
  <div class="root">
    <div style="height: 30px;text-align: center;">
      我的
    </div>
    <div style="display:flex;padding: 0 20px" v-if="$store.getters.getUser.userId !== undefined" @click="toMyProfile">
      <el-avatar :size="80" :src="file.get + $store.getters.getUser.userAvatar + '?X-T='+$store.getters.getUser.token"></el-avatar>
      <div style="display:flex;justify-content: space-between;flex: 1;padding: 0 2px">
        <div style="font-size: 10px;padding: 10px 0;display:flex;flex-direction: column;justify-content: space-around;">
          <div>{{$store.getters.getUser.userName}}</div>
          <div>ID: <span>{{ $store.getters.getUser.userId }}</span></div>
        </div>
        <div style="display:flex;flex-direction: column;justify-content: space-around;">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div style="display:flex;padding: 0 20px" v-else @click="toLogin">
      <el-avatar :size="80"></el-avatar>
      <div style="display:flex;justify-content: space-between;flex: 1;padding: 0 2px">
        <div style="font-size: 10px;padding: 10px 0;display:flex;flex-direction: column;justify-content: space-around;">
          <div>还未登录</div>
        </div>
        <div style="display:flex;flex-direction: column;justify-content: space-around;">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <!--    <div style="border: 1px solid blue;">-->
    <!--      <div style="-->
    <!--            border: 1px solid green;-->
    <!--            display: grid;-->
    <!--            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));-->
    <!--            gap: 10px;-->
    <!--            justify-items: center;-->
    <!--            align-items: center;-->
    <!--            padding: 10px;">-->
    <!--        &lt;!&ndash;      <div style="width: 50px;height: 50px;border: 1px solid red;box-sizing: border-box;"></div>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
    <div style="display:flex;flex-direction: column;margin: 5px 0;padding:5px;background: white;">
      <div style="width: 100%;border-bottom: 1px #949494 solid"></div>
      <div v-if="$store.getters.getUser.userId !== undefined">
        <div style="width: 100%;border-bottom: 1px #949494 solid;">
          <div style="display: inline-block;">🔐</div>
          <div style="display: inline-block;">忘记密码</div>
        </div>
        <div style="width: 100%;border-bottom: 1px #949494 solid;">
          <div style="display: inline-block;">🔑</div>
          <div style="display: inline-block;">隐私设置</div>
        </div>
        <div style="width: 100%;border-bottom: 1px #949494 solid;">
          <div style="display: inline-block;">⚙️</div>
          <div style="display: inline-block;">设置</div>
        </div>
      </div>
      <div style="width: 100%;border-bottom: 1px #949494 solid;">
        <div style="display: inline-block;">❤️</div>
        <div style="display: inline-block;">联系客服</div>
      </div>
    </div>
    <div style="display:flex;justify-content: center;margin-top: 6px">
      <el-button size="small" round style="width: 60%;color: rgba(253,59,59,0.55)" v-if="$store.getters.getUser.userId !== undefined" @click="loginOut">退出登录</el-button>
      <el-button size="small" round style="width: 60%;color: rgb(154,215,255)" v-else @click="toLogin">登录</el-button>
    </div>
  </div>
</template>

<script>

import {getLang} from "@/common/language";
import {file} from "@/api/api";

export default {
  name: 'mainPage',
  computed: {
    file() {
      return file
    }
  },
  data() {
    return {}
  },
  created() {
    // this.load()
  },
  mounted() {
    this.init()
  },
  methods: {
    getLang,
    init() {

    },
    toLogin() {
      this.$router.push('/holiday/login')
    },
    toMyProfile() {
      this.$router.push('/holiday/page/myProfile')
    },
    loginOut() {
      this.$message.success(getLang('退出登录'))
      this.$router.push('/holiday/login')
      this.$store.commit('loginOut')
    },
  }
}
</script>
<style>
.root {
  display: flex;
}
</style>

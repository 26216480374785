<template>
  <div class="chat-container">
    <div class="chat-messages">
      <div v-for="(message, index) in messages" :ref="message.id" :key="index" class="message-line-box">
        <div :class="message.senderId !== $store.getters.getUser.userId ? 'message-left':'message-right'">
          <div class="message-sender">
            <el-avatar :size="25"
                       :src="fileUrl.get + $store.getters.getUser.userAvatar + '?X-T='+$store.getters.getUser.token"></el-avatar>
          </div>
          <div class="message-box"
               :style="message.senderId !== $store.getters.getUser.userId ? {background: '#409eff'} : {background: '#42bb89'}">
            <div class="message">{{ message.message }}</div>
            <div v-if="message.fileList !== undefined" class="message-file">
              <div v-for="(file, index) in message.fileList"
                   v-bind:key="index">
                <div v-if="isImage(file.fileType)" style="position: relative;">
                  <el-image
                      style="width: 100px; height: 100px"
                      :src="fileUrl.get + file.id + '?X-T=' + $store.getters.getUser.token"
                      fit="scale-down"></el-image>
                  <div class="overlay"></div>
                </div>
                <div v-else>
                  <div style="display:flex;flex-direction: column">
                    <div>
                      <el-icon style="font-size: 40px" class="el-icon-document"></el-icon>
                    </div>
                    {{ file.fileName }}
                  </div>
                  <div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="message-time" style="font-size: 10px;"
             :style="message.senderId !== $store.getters.getUser.userId ? {left:'30px'} : {right:'30px'}">
          {{ message.createTime }}
        </div> 
      </div>
<!--      <div v-if="sending" ref="sending" class="message-line-box">-->
<!--        <div class="message-right">-->
<!--          <div class="message-sender">{{ unSendMessag.senderId }}</div>-->
<!--          <div class="message-box">-->
<!--            <div class="message">{{ unSendMessag.message }}</div>-->
<!--            <div v-if="unSendMessag.file !== undefined && unSendMessag.file !== ''" class="message-file">-->
<!--              <div>文件 {{ unSendMessag.file }}</div>-->
<!--              <img v-for="(file, index) in unSendMessag.file.split(',')" :src="fileUri.get + file" alt=""-->
<!--                   v-bind:key="index">-->
<!--            </div>-->
<!--            <div style="font-size: 10px">正在发出消息</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div style="display:flex;padding: 5px 5px;background: rgb(237 240 243)">
      <el-input
          placeholder="Type a message..."
          @keyup.enter="sendMessage"
          v-model="newMessage"
          clearable>
      </el-input>
      <div style="width:80px;display:flex;align-items: center;justify-content: center;">
        <el-button size="small" type="primary" @click="sendMessage"
                   :disabled="filesList.length === 0 && newMessage === ''">
          发送
        </el-button>
      </div>

      <div style="width:80px;display:flex;align-items: center;justify-content: center;">
        <el-button size="small" type="primary" v-if="!showMoreNext.show" @click="openMore()">更多</el-button>
        <el-button size="small" type="primary" v-if="showMoreNext.show" @click="openMore()">返回</el-button>
      </div>
    </div>
    <div class="menu-button-container" v-if="showMore">
      <div v-for="(button, index) in moreButton" :key="index" class="menu-button">
        <el-button style="right: 0" size="small" type="primary" @click="showMoreToNext(button.type)">{{ button.name }}</el-button>
      </div>
    </div>
    <el-upload
        v-if="showMoreNext.type === 'file'"
        :on-remove="handleRemove"
        list-type="text"
        :on-success="onSuccess"
        :on-error="onError"
        :http-request="upload"
        :file-list="fileList"
        :multiple="false" action="#">
      <el-button style="right: 0" slot="trigger" size="small" type="primary" :disabled="filesList.length > 0">选取文件
      </el-button>
    </el-upload>
    <el-upload
        v-if="showMoreNext.type === 'image'"
        :on-remove="handleRemove"
        list-type="picture-card"
        :on-success="onSuccess"
        :on-error="onError"
        :http-request="upload"
        :accept="'image/*,video/*'"
        :file-list="fileList"
        :multiple="false" action="#">
      <div slot="file" slot-scope="{file}">
        <div>
          <img v-if="file.raw.type.startsWith('image/')"
               class="el-upload-list__item-thumbnail"
               :src="file.url" alt=""
          >
          <div class="overlay">点击删除</div>
        </div>
        <span class="el-upload-list__item-actions">
          <span
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <el-button style="right: 0" slot="trigger" size="small" type="primary" :disabled="filesList.length > 0">选取文件
      </el-button>
    </el-upload>
    <el-dialog :visible="showMoreNext.type === 'users'"
               :show-close="true" title="成员列表" :destroy-on-close='true' :before-close="initMenu" center>
      <div style="background: white;padding: 0 15px">
        <div>
          <el-button size="small" @click="showAdd=true">add</el-button>
        </div>
        <div v-for="(user, index) in room.userEntityList" v-bind:key="index">
          <div v-if="user.id !== $store.getters.getUser.userId" style="display:flex; justify-content: space-between; align-items: center;">
            <div> {{ user.username }} </div>
            <div>
              <el-button size="small">踢</el-button>
            </div>
          </div>
        </div>
        <el-dialog :visible="showAdd"
                   :show-close="true" title="成员列表" :destroy-on-close='true' :before-close="initMenu" center>
          <div style="background: white;padding: 0 15px">
            <div>
              添加你的好友进来
            </div>
            <el-input v-model="addCode"></el-input>
            <el-button size="small" @click="addMember">确定</el-button>
          </div>
        </el-dialog>
      </div>
    </el-dialog>
    <el-dialog :visible="showMoreNext.type === 'call'"
               :show-close="true" title="成员列表" :destroy-on-close='true' :before-close="initMenu" center>
      <div style="background: white;padding: 0 15px">
        <div v-for="(user, index) in room.userEntityList" v-bind:key="index">
          <div v-if="user.id !== $store.getters.getUser.userId" style="display:flex; justify-content: space-between; align-items: center;">
            <div> {{ user.username }} </div>
            <el-tag size="mini" type="success" effect="plain" v-if="user.online">在线</el-tag>
            <el-tag size="mini" type="danger" effect="plain" v-else>离线</el-tag>
            <div>
              <el-button size="small" @click="call(user.id)" :disabled="!user.online">打电话</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <audio ref="remoteAudio" autoplay playsinline></audio>
  </div>
</template>
<script>
import {get, post} from "@/api/axios";
import {chat, file} from "@/api/api";
import axios from "axios";
import {Message} from "element-ui";

export default {
  name: 'chat',
  computed: {
    fileUrl() {
      return file
    },
  },
  data() {
    return {
      room: this.$route.params,
      fileType: undefined,
      filesList: [],
      fileList: [],
      messages: [],
      newMessage: '',
      unSendMessage: {},
      sending: false,
      showMore: false,
      showMoreNext: {
        show: false,
        type: '',
      },
      nowId: 0,
      moreButton: [
        {name: "发送文件", type: "file"},
        {name: "发送图片", type: "image"},
        {name: "成员列表", type: "users"},
        {name: "语音通话", type: "call"},
        {name: "视频通话", type: ""},
      ],
      imageTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml', 'image/bmp', 'image/tiff'],
      showAdd: false,
      addCode: undefined,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.getRoom()
      await this.getFirstMessage().then(() => {
        if (this.messages.length > 0) {
          this.scrollToSection(this.messages[this.messages.length - 1].id)
        }
      })
      this.startTimer();
    },
    openMore() {
      this.showMore = !this.showMore
      this.initMenu()
    },
    initMenu() {
      this.fileType = undefined
      this.fileList = []
      this.filesList = []
      this.showMoreNext = {
        show: false,
        type: '',
      }
      this.showAdd = false
      this.addCode = undefined
    },
    showMoreToNext(type) {
      this.fileType = type
      this.showMore = false
      this.showMoreNext.show = true
      this.showMoreNext.type = type
      if (type === 'users' || type === 'call') {
        this.getRoom()
      }
    },
    scrollToSection(id) {
      this.$nextTick(() => {
        this.$refs[id][0].scrollIntoView({behavior: 'auto'})
      })
    },
    sendMessage() {
      if (this.newMessage.trim() !== '' || this.filesList.length !== 0) {
        this.sending = true
        // 模拟提交到后台服务器
        this.unSendMessage = {
          roomId: this.room.id,
          message: this.newMessage.trim(),
          file: this.filesList.join(','),
        };
        console.log(this.unSendMessage)
        post(chat.subMessage, this.unSendMessage, () => {
          this.sending = false
          this.unSendMessage = {}
          this.newMessage = '';
          this.initMenu()
          this.getMessage()
        }, () => {
        })
      }
    },
    upload(data) {
      console.log(data)
      let formData = new FormData();
      formData.append("file", data.file);
      console.log(formData)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-T': this.$store.getters.getUser.token
        }
      }
      let that = this
      axios.post(file.up, formData, config).then(function (response) {
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (response.data.message !== null && response.data.message !== '') {
              Message.success(response.data.message)
            }
            that.filesList.push(response.data.data)
            data.onSuccess(response, data.file, that.filesList)
          } else {
            // this.files = this.files.filter(item => item.uid !== data.file.uid);
            if (response.data.message !== null && response.data.message !== '') {
              Message.error(response.data.message)
            }
            data.onError(response, data.file, that.filesList)
          }
        } else {
          data.onError(response, data.file, that.filesList)
        }
      })
    },
    onSuccess(response, file, fileList) {
      console.log("success")
      console.log(response)
      console.log(file)
      this.fileList = fileList
    },
    onError(err, file, fileList) {
      console.log("error")
      console.log(err)
      console.log(file)
      console.log(fileList)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
        this.filesList.splice(index, 1);
      }
    },
    async getFirstMessage() {
      let param = {
        roomId: this.room.id
      }
      if (this.messages.length > 0) {
        const last = this.messages[this.messages.length - 1]
        param.id = last.id
      }
      // 返回一个 Promise
      return new Promise((resolve, reject) => {
        get(chat.getFirstMessage, param, res => {
          this.messages.push(...res);
          this.scrollToSection(this.messages[this.messages.length - 1].id)
          resolve();
        }, error => {
          reject(error);
        });
      });
    },
    async getMessage() {
      let param = {
        roomId: this.room.id,
        id: undefined
      }
      if (this.messages.length > 0) {
        const last = this.messages[this.messages.length - 1]
        param.id = last.id
      }
      get(chat.getMessage, param, res => {
        this.messages.push(...res);
        if (this.messages.length > 0 && this.messages[this.messages.length - 1].senderId === this.$store.getters.getUser.userId && this.nowId !== this.messages[this.messages.length - 1].id) {
          this.nowId = this.messages[this.messages.length - 1].id
          this.scrollToSection(this.messages[this.messages.length - 1].id)
        }
      }, error => {
        console.log(error)
      });
    },
    getRoom() {
      get(chat.getRoom, {id: this.room.id}, res => {
        this.room = res
        this.$emit('setTitle', res.roomName)
      }, () => {
      })
    },
    startTimer() {
      // 开始定时任务，每秒执行一次
      this.intervalId = setInterval(() => {
        // 这里可以执行你的具体逻辑，比如发送请求、更新数据等
        this.getMessage()
        // 每次执行完逻辑后，增加计数器
        this.counter++;
      }, 1000);
    },
    stopTimer() {
      // 停止定时任务
      clearInterval(this.intervalId);
      console.log('定时任务已停止');
    },
    call(id) {
      const remoteAudio = this.$refs['remoteAudio'];
      this.$websocket.startCalling(id, remoteAudio)
      this.initMenu()
    },
    isImage(fileType) {
      return this.imageTypes.includes(fileType);
    },
    addMember() {
      get(chat.addMember, {id: this.room.id, userList: this.addCode}, () => {
      }, () => {
      })
    },
  },
  beforeDestroy() {
    // 组件销毁前停止定时任务，避免内存泄漏
    this.stopTimer();
  }
}

</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
}

.message-line-box {
  margin: 15px 5px 5px;
  position: relative;
}

.message-right {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.message-right .message-sender {
  text-align: right;
}

.message-time {
  position: absolute;
}

.message-left {
  display: flex;
}

.message-sender {
  margin: 0 5px;
  font-weight: bold;
  width: 25px;
  height: 25px;
}

.message-box {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
}

.message {
  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.chat-input {
  display: flex;
  align-items: center;
}

.chat-input input {
  flex: 1;
  margin-right: 5px;
}

.chat-input button {
  padding: 5px 10px;
  cursor: pointer;
}

.message-image img {
  max-width: 100%;
}

.upload-demo >>> .el-upload-list {
  overflow-x: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-button-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  width: 100%;
  padding: 10px;
}
.menu-button {

}
</style>
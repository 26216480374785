<template>
  <div class="cn">
    <Header :title="title"/>
    <div class="body">
      <router-view @setTitle="setTitle"/>
    </div>
  </div>
</template>
<script>

import Header from "@/components/Header.vue";

export default {
  name: 'ContainerPage',
  data() {
    return {
      title: ''
    }
  },
  comments: {},
  components: {
    Header
  },
  mounted() {
    this.$store.commit('cancelLoading')
  },
  methods: {
    setTitle(title) {
      this.title = title
      console.log(this.title)
    }
  }
}
</script>
<style>
</style>
<style scoped>
.cn {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.body {
  min-height: 0;
  flex: 1;
  display: flex;
  overflow-y: auto;
  background: #f2f8fd;
}

</style>

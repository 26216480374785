var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root"},[_c('div',{staticStyle:{"height":"30px","text-align":"center"}},[_vm._v(" 我的 ")]),(_vm.$store.getters.getUser.userId !== undefined)?_c('div',{staticStyle:{"display":"flex","padding":"0 20px"},on:{"click":_vm.toMyProfile}},[_c('el-avatar',{attrs:{"size":80,"src":_vm.file.get + _vm.$store.getters.getUser.userAvatar + '?X-T='+_vm.$store.getters.getUser.token}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","flex":"1","padding":"0 2px"}},[_c('div',{staticStyle:{"font-size":"10px","padding":"10px 0","display":"flex","flex-direction":"column","justify-content":"space-around"}},[_c('div',[_vm._v(_vm._s(_vm.$store.getters.getUser.userName))]),_c('div',[_vm._v("ID: "),_c('span',[_vm._v(_vm._s(_vm.$store.getters.getUser.userId))])])]),_vm._m(0)])],1):_c('div',{staticStyle:{"display":"flex","padding":"0 20px"},on:{"click":_vm.toLogin}},[_c('el-avatar',{attrs:{"size":80}}),_vm._m(1)],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin":"5px 0","padding":"5px","background":"white"}},[_c('div',{staticStyle:{"width":"100%","border-bottom":"1px #949494 solid"}}),(_vm.$store.getters.getUser.userId !== undefined)?_c('div',[_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),_vm._m(5)]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"6px"}},[(_vm.$store.getters.getUser.userId !== undefined)?_c('el-button',{staticStyle:{"width":"60%","color":"rgba(253,59,59,0.55)"},attrs:{"size":"small","round":""},on:{"click":_vm.loginOut}},[_vm._v("退出登录")]):_c('el-button',{staticStyle:{"width":"60%","color":"rgb(154,215,255)"},attrs:{"size":"small","round":""},on:{"click":_vm.toLogin}},[_vm._v("登录")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-around"}},[_c('i',{staticClass:"el-icon-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"space-between","flex":"1","padding":"0 2px"}},[_c('div',{staticStyle:{"font-size":"10px","padding":"10px 0","display":"flex","flex-direction":"column","justify-content":"space-around"}},[_c('div',[_vm._v("还未登录")])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-around"}},[_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","border-bottom":"1px #949494 solid"}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("🔐")]),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("忘记密码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","border-bottom":"1px #949494 solid"}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("🔑")]),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("隐私设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","border-bottom":"1px #949494 solid"}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("⚙️")]),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","border-bottom":"1px #949494 solid"}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("❤️")]),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("联系客服")])])
}]

export { render, staticRenderFns }
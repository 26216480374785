export const zh_cn = {
  "Home": "首页",
  "Preferential": "福利",
  "All": "所有",
  "Preferential Detail": "活动详情",

  "My": "我的",
  "Central Wallet": "中心钱包",
  "You Are Not Logged In": "您还未登录",
  "Deposit": "存款",
  "Payment Method": "支付方式",
  "Deposit History": "历史充值",
  "Bank Transfer": "银行转账",
  "Virtual Currency": "虚拟货币",
  "Status": "状态",
  "Loading": "加载中",
  "Load Failed": "加载失败",
  "No More": "没有更多了",
  "Choose Bank Info": "选择银行卡信息",
  "Choose Virtual Address Info": "选择虚拟货币地址信息",
  "Deposit Amount": "存款金额",
  "Enter Deposit Amount": "输入存款金额",
  "Deposit Now": "立即存款",
  "Tips: Please be sure to pay according to the selected amount so that you can quickly verify that it has arrived!": "温馨提示：请您务必按照选定金额支付，以便为您快速核实到账！",
  "Deposit Order": "存款订单",
  "Waiting For Review": "等待审核",
  "Succeed": "成功",
  "Fail": "失败",
  "Order Code": "订单编号",
  "Order Time": "订单时间",
  "Virtual Address": "虚拟地址",
  "Virtual Amount": "虚拟金额",
  "Currency Code": "货币编码",

  "Transfer": "转账",
  "Withdrawal": "提款",
  "My Balance": "我的余额",
  "My Historical Withdrawal": "我的历史提款",
  "Withdrawal Amount": "提款金额",
  "Enter Withdrawal Amount": "输入提款金额",
  "Withdrawal Now": "立即提款",
  "Withdrawal Order": "提款订单",
  "Withdrawal History": "历史提款",

  "Click login in/register": "点击登录/注册",
  "VIP": "VIP",

  "JOIN yx": "加入",
  "day": "天",
  "Promotion Flow": "晋级流水",
  "More": "更多",
  "benefits": "特权",
  "Weekly red envelopes": "每周红包",
  "Promotion Bonus": "晋级礼金",
  "Exclusive gifts": "专属好礼",
  "Birthday gift": "生日礼金",
  "Join VIP enjoy Exclusive gifts": "加入VIP专享好礼",
  "Login/Register To View": "登录注册后查看",
  "Transactions": "交易记录",
  "Betting History": "投注记录",
  "Today": "今天",
  "Yesterday": "昨天",
  "NearlySevenDays": "近七天",
  "ThirtyDays": "三十天",
  "Select The Year Month And Day": "选择年月日",
  "StartTime": "开始时间",
  "EndTime": "结束时间",
  "customization": "自定义",
  "cancel": "取消",
  "confirm": "确定",

  "Redemption History": "兑奖记录",
  "Account Management": "账户管理",
  "Add Bank": "添加账户",
  "Add Virtual Address": "添加虚拟地址",
  "Confirm Add": "确认添加",


  "Invite Friends": "邀请好友",
  "Feedback": "意见反馈",
  "Help Center": "帮助中心",
  "Join Us": "加入我们",
  "Refresh Successful": "刷新成功",
  "Language": "语言",
  "Customer": "在线客服",
  "ChineseSimplified": "简体中文",
  "English": "英语",
  "Login Out": "退出登录",

  "Account": "用户名",
  "Password": "密码",
  "Verification Code": "验证码",
  "Forget Password": "忘记密码",
  "Login": "登录",
  "No account?": "没有账号？",
  "Sign up now!": "现在注册！",
  "Take a look first": "先去逛逛",
  "Confirm Password": "确认密码",
  "Email": "邮箱",
  "Real Name": "真实姓名",
  "Bank Name": "银行名称",
  "Bank Code": "银行卡号",
  "Bank Address": "开户行地址",
  "Recommendation Id": "推荐ID",
  "Phone Verification Code": "手机验证码",
  "Get verification codes frequently!": "获取验证码频繁！",
  "send": "发送",
  "I have read and agree": "我已阅读并同意",
  "Confirmation Password Is Incorrect": "确认密码不一致",
  "Register": "注册",
  "Registration successful!": "注册成功！",
  "Already Have Account?": "已经有了账号？",
  "Login!": "登录！",
  " is empty": "为空",

  "Phone Number": "手机号码",

  "Change Password": "修改密码",
  "Original Password": "原始密码",
  "Submit": "提交",
  "New Password": "新密码",
  "Enter Password Again": "再次输入密码",

  "Secondary Password": "二级密码",
  "New Secondary Password": "新二级密码",
  "Enter New Secondary Password Again'": "再次输入二级密码",

  "you are not login yet": "你还没有登录",

  "Gift Pack Code Redemption": "礼包码兑换",
  "My message": "我的消息",
  'Redemption code':'兑换码',
  'exchange':'兑换',
  'level':'等级',
  'Growth Value':'成长值',
  'Upgrade bonus':'升级奖金',
  'Fee free withdrawal limit':'免手续费提款额度',
  'Withdrawal handling fee':'提现手续费',
  'Betting rebate':'下注返利',
}

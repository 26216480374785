import {baseURL} from "@/api/axios";

export const login = {
  login: "user/login",
  playerRegister: "user/register",
  sendCode: "user/sendCode",
}

export const chat = {
  getRoomList: "chat/getRoomList",
  createRoom: "chat/createRoom",
  createSareCode: "chat/createSareCode",
  getFirstMessage: "chat/getFirstMessage",
  getMessage: "chat/getMessage",
  getRoom: "chat/getRoom",
  subMessage: "chat/subMessage",
  addMember: "chat/addMember",
  getCallSession: "chat/getCallSession",
}

export const my = {
  changeMyAvatar: "my/changeMyAvatar",
}

export const file = {
  up: baseURL + "/fileSystem/upload",
  get: baseURL + "/fileSystem/",
  subMessage: "message/subMessage",
}

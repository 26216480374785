<template>
  <div class="root">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'Container',
  data() {
    return {
      store: {}
    }
  },
  comments: {},
  components: {
  },
  mounted() {
  },
  methods: {}
}
</script>
<style>

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

</style>
<style scoped>

</style>

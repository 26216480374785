import Vue from 'vue';
import SimplePeer from "simple-peer";

class WebSocketService {
  static instance = null;
  ws = null;
  myMessage = [];
  websocketUrl = `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api/ws`;
  store = null;
  heartbeat = null;
  reconnectInterval = null;

  peer = null;
  remoteAudio = null;

  startCalling(id, remoteAudio) {
    this.call.calling = true
    this.call.callId = id
    this.send(JSON.stringify({
      type: 'Call',
      message: 'Call',
      token: this.store.getters.getUser.token,
      to: id
    }))
    this.remoteAudio = remoteAudio;
  }

  cancelCall() {
    console.log("本方取消通话")
    this.call.isCall = false;
    this.call.getCall = false;
    this.call.calling = false;
    this.peer.destroy()
    this.send(JSON.stringify({
      type: 'CancelCall',
      message: 'CancelCall',
      token: this.store.getters.getUser.token,
      to: this.call.callId
    }))
  }

  answerCall() {
    console.log("本方答应通话")
    this.call.isCall = true;
    this.call.getCall = false;
    this.call.calling = false;
    this.send(JSON.stringify({
      type: 'AnswerCall',
      message: 'AnswerCall',
      token: this.store.getters.getUser.token,
      to: this.call.callId
    }))
  }

  static get Instance() {
    if (!this.instance) {
      this.instance = new WebSocketService();
    }
    return this.instance;
  }

  init(store) {
    this.store = store;
    this.call = Vue.observable({
      isCall: false,
      getCall: false,
      calling: false,
      callId: undefined,
    });
    this.peer_obs = Vue.observable({
      status: '0',
    });
    this.wss = Vue.observable({
      status: '未连接',
      myMessage: [],
    });
    this.startWsStatusMonitor()
    if (process.env.NODE_ENV === 'development') {
      this.websocketUrl = 'ws://192.168.3.200:8090/ws'
    }
  }

  connect() {
    clearInterval(this.heartbeat)
    if (this.store.getters.getUser.token !== undefined) {
      try {
        this.ws = new WebSocket(this.websocketUrl + `?token=${this.store.getters.getUser.token}`);
        this.ws.onopen = this.onOpen.bind(this);
        this.ws.onmessage = this.onMessage.bind(this);
        this.ws.onclose = this.onClose.bind(this);
        this.ws.onerror = this.onError.bind(this);
      } catch (e) {
        this.onClose()
        console.log(e)
      }
    } else {
      this.onClose()
    }
  }

  close() {
    console.log('close')
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    clearInterval(this.heartbeat)
    clearInterval(this.wsStatusMonitor)
    this.startWsStatusMonitor()
  }

  startHeartbeat() {
    console.log('启动心跳')
    this.heartbeat = setInterval(() => {
      if (this.store.getters.getUser.token !== undefined) {
        if (this.ws) {
          if (this.ws.readyState === WebSocket.OPEN) {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
              this.ws.send(JSON.stringify({
                type: 'heartbeat',
                message: 'ping',
                token: this.store.getters.getUser.token
              }));
            } else {
              this.heartbeat = null
            }
          } else if (this.ws.readyState === WebSocket.CLOSED) {
            clearInterval(this.heartbeat)
            this.wss.status = '关闭'
            console.log('ws已经关闭')
          } else {
            this.wss.status = '其他状态'
            console.log('ws处于其他状态', this.ws.readyState)
          }
        } else {
          this.wss.status = '未初始化'
          console.log('ws 未初始化')
        }
      } else {
        this.close();
        this.heartbeat = null
      }
    }, 1000);
  }

  onOpen() {
    console.log('WebSocket connected');
    this.wss.status = 'onOpen'
    this.startHeartbeat()
    clearInterval(this.reconnectInterval)
  }

  onMessage(event) {
    const data = JSON.parse(event.data);
    if (data.type === 'NoLoginException') {
      this.store.commit('logout', true)
      this.close()
    }
    if (data.type !== 'heartbeat') {
      this.wss.myMessage.push(data)
    }
    if (data.type === 'Call') {
      console.log('收到电话消息')
      // 收到电话消息
      this.call.getCall = true
      this.call.callId = data.from
    }
    if (data.type === 'AnswerCall') {
      console.log('收到对方同意')
      // 收到对方同意
      this.call.isCall = true;
      this.call.getCall = false;
      this.call.calling = false;
      this.startCall(this.remoteAudio);
    }
    if (data.type === 'CancelCall') {
      console.log('收到对方拒绝')
      this.peer.destroy();
      this.call.isCall = false;
      this.call.getCall = false;
      this.call.calling = false;
    }
    if (data.type === 'peer') {
      const p_data = JSON.parse(data.message);
      if (this.peer === null) {
        console.log('本方peer未初始化')
      } else {
        if (p_data.type === 'offer' || p_data.type === 'answer' || p_data.candidate) {
          this.peer.signal(p_data);
        }
      }
    }
  }

  startWsStatusMonitor() {
    this.wsStatusMonitor = setInterval(() => {
      if (this.ws) {
        if (this.ws.readyState === WebSocket.CLOSED) {
          // 等待登录重连
          if (this.store.getters.getUser.token !== undefined) {
            this.wss.status = '重连尝试'
            console.log('ws 重连尝试')
            this.connect()
          } else {
            this.close()
            this.wss.status = '等待登录重连'
            console.log('等待登录重连')
          }
        } else if (this.ws.readyState === WebSocket.CONNECTING) {
          this.wss.status = '连接尝试'
          console.log("ws 正在尝试连接")
        } else if (this.ws.readyState === WebSocket.OPEN) {
          this.wss.status = '以打开'
        } else if (this.ws.readyState === WebSocket.CLOSING) {
          this.wss.status = '正在关闭'
        }
      } else {
        this.wss.status = '未初始化'
        console.log('ws 未初始化')
        if (this.store.getters.getUser.token !== undefined) {
          console.log("ws 进行初始化")
          this.connect()
        }
      }
    }, 3000);
  }

  onClose() {
    console.info('WebSocket closed:');
  }

  onError(error) {
    this.wss.status = 'onError'
    console.error('WebSocket error:', error);
  }

  send(data) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(data);
    } else {
      console.error('WebSocket is not open');
    }
  }

  startCall(remoteAudio) {
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
      .then(stream => {
        this.peer = new SimplePeer({
          initiator: true,
          trickle: false,
          stream: stream
        });
        console.log('Peer 初始化');
        this.peer_obs.status = 1
        this.setupPeer(this.peer, remoteAudio);
      })
      .catch(err => {
        console.error('Error accessing media devices:', err);
      });
  }

  setupPeer(peer, remoteAudio) {
    this.peer.on('signal', data => {
      this.send(JSON.stringify({
        type: 'peer',
        to: this.call.callId,
        message: JSON.stringify(data),
        token: this.store.getters.getUser.token,
      }))
    })

    this.peer.on('connect', () => {
      console.log('Peer 链接成功');
      this.peer_obs.status = 2
    });

    this.peer.on('close', () => {
      console.log("peer关闭")
      this.call.isCall = false;
      this.call.getCall = false;
      this.call.calling = false;
      this.callId = undefined;
      this.peer_obs.status = 0
      this.peer = null
    })
    this.peer.on('stream', stream => {
      remoteAudio.srcObject = stream
    })
    this.peer.on('error', err => {
      console.log("peer 异常")
      console.error(err)
    })
  }
}

export default WebSocketService;
import Vue from 'vue'
import VueRouter from 'vue-router'

import Container from '@/components/Container.vue'
import ContainerOne from "@/components/ContainerCenter.vue";
import ContainerTwo from "@/components/ContainerPage.vue";
import index from '@/views/center/index.vue'
import {sessionStorageStore} from "@/util/constant";
import {fromCode, getEadCode, toCode} from "@/util/EAD";
import {getLang} from "@/common/language";
import ElementUI from "element-ui";
import ContainerChristmas from "@/views/christmas/main/ContainerChristmas.vue";
import GreetingCardSend from "@/views/christmas/main/GreetingCardSend.vue";
import ChristmasMy from "@/views/christmas/main/ChristmasMy.vue";
import MyGreetingCard from "@/views/christmas/main/MyGreetingCard.vue";
import CenterLogin from "@/views/login.vue";
import room from "@/views/center/chat/room.vue";
import home from "@/views/center/home.vue";
import center_my from "@/views/center/my/My.vue";
import center_my_profile from "@/views/center/my/MyProfile.vue";
import chat from "@/views/center/chat/chat.vue";
import yuushaMain from "@/views/yuusha/main.vue";
import call from "@/views/center/chat/call.vue";
import v_call from "@/views/center/chat/v-call.vue";
import webclip from "@/views/center/test_webclip.vue";
import HolidayLogin from "@/views/holiday/holidayLogin.vue";
import holidayHome from "@/views/holiday/holidayHome.vue";
import HolidayContainerCenter from "@/views/holiday/HolidayContainerCenter.vue";
import holidayMy from "@/views/holiday/holidayMy.vue";

let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (checkLogin.includes(location)) {
    if (router.app.$options.store.getters.getUser.token === undefined) {
      innerMessage('error', getLang("you are not login yet"))
      router.push('/user/login').catch(() => {
      })
      return
    }
  }
  originPush.call(this, location, resolve, reject).catch(error => error)
}

Vue.use(VueRouter)

function innerMessage(type, message) {
  ElementUI.Message({
    showClose: true,
    duration: 2000,
    message: message,
    type: type
  })
}

export const routes = [
  // {
  //   path: '/',
  //   redirect: '/center/main/home'
  // },
  {
    path: '/',
    redirect: '/yuusha/main'
  },
  {
    path: '/yuusha',
    component: Container,
    children: [
      {
        path: 'main',
        name: 'yuushaMain',
        component: yuushaMain
      },
    ]
  },
  {
    path: '/center',
    component: Container,
    children: [
      {
        path: 'login',
        name: 'centerLogin',
        component: CenterLogin
      },
      {
        path: 'index',
        name: 'index',
        component: index
      },
      {
        path: 'main',
        name: 'centerMain',
        component: ContainerOne,
        children: [
          {path: 'home', name: 'home', component: home,},
          {path: 'roomList', name: 'roomList', component: room,},
          {path: 'my', name: 'center_my', component: center_my,}
        ]
      },
      {
        path: 'page',
        component: ContainerTwo,
        children: [
          {path: 'chat/:id', name: 'chat', component: chat,},
          {path: 'call', name: 'call', component: call,},
          {path: 'video_call', name: 'v_call', component: v_call,},
          {path: 'myProfile', name: 'center_my_profile', component: center_my_profile,},
          {path: 'webclip', name: 'webclip', component: webclip,},
        ]
      }
    ]
  },
  {
    path: '/holiday/',
    name: 'HolidayC',
    component: Container,
    children: [
      {
        path: 'main',
        name: 'HolidayM',
        component: HolidayContainerCenter,
        children: [
          {path: 'home', name: 'holidayHome', component: holidayHome,},
          {path: 'my', name: 'holidayMy', component: holidayMy,}
        ]
      },
    ]
  },
  {
    path: '/holiday/login',
    name: 'HolidayLogin',
    component: HolidayLogin,
  },
  {
    path: '/christmas',
    component: Container,
    children: [
      {
        path: 'main',
        name: 'christmasMain',
        component: ContainerChristmas,
        children: [
          {path: 'GreetingCardSend', name: 'GreetingCardSend', component: GreetingCardSend,},
          {path: 'MyGreetingCard', name: 'MyGreetingCard', component: MyGreetingCard,},
          {path: 'ChristmasMy', name: 'ChristmasMy', component: ChristmasMy,},
        ]
      },
      {
        path: 'page',
        component: ContainerTwo,
        children: []
      }
    ]
  }
]

export const checkLogin = [
  "/hash/page/transactions",
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
  if (to.name === null || to.name === 'mainShell') {
    console.log("wrongRouting")
    router.push('/center/main/home').catch(() => {
    })
    return
  }
  let store = {}
  if (sessionStorage.getItem(sessionStorageStore)) {
    let item = sessionStorage.getItem(sessionStorageStore);
    let code = item.substring(0, 36);
    let fCode = fromCode(code, item.substring(36));
    store = JSON.parse(fCode)
    router.app.$options.store.replaceState(
      Object.assign(
        {},
        router.app.$options.store.state,
        store
      )
    )
    sessionStorage.removeItem(sessionStorageStore)
  }
  window.addEventListener('beforeunload', () => {
    if (router.app.$options.store.getters.getEadCode === "") {
      console.log("reload")
      router.app.$options.store.commit('setEadCode', getEadCode())
    }
    sessionStorage.setItem(sessionStorageStore, router.app.$options.store.getters.getEadCode + toCode(JSON.stringify(router.app.$options.store.state)))
  })
  next()
})

router.afterEach(() => {
  router.app.$options.store.commit('cancelLoading')
})

router.beforeResolve((to, from, next) => {
  if (checkLogin.includes(to.path)) {
    if (router.app.$options.store.getters.getUser.token === undefined) {
      innerMessage('error', getLang("you are not login yet"))
      if (from.path === '/user/login') {
        router.push('/center/main/index').catch(() => {
        })
      } else {
        router.push('/user/login').catch(() => {
        })
      }
      return
    }
  }
  next()
})

export default router

import store from '../store'
// import router from '../router'
import axios from 'axios'
import ElementUI from 'element-ui'
import router from "@/router";

export let baseURL = process.env.VUE_APP_API_BASE_URL

//export let baseURL = 'https://www.lmybet777.com/api/'

//export let officeURL = 'http://localhost/'

function innerMessage(type, message) {
  ElementUI.Message({
    showClose: true,
    duration: 2000,
    message: message,
    type: type
  })
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  withCredentials: true
})

axiosInstance.interceptors.request.use(config => {
  setLoading()
  config.headers['Content-Type'] = 'application/json;charset=UTF-8'
  if (store.getters.getUser && store.getters.getUser.token) {
    config.headers['X-T'] = store.getters.getUser.token
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
    const {data, message, code} = response.data
    // console.log(response.data)
    if (message !== null && message !== '') {
      if (code !== 200) {
        innerMessage('error', message)
      } else {
        innerMessage('info', message)
      }
    }
    cancelLoading()
    if (code === 401) {
      store.commit('logout', true)
      router.push("/center/login").catch(() => {
      })
    }
    if (code !== 200) {
      return Promise.reject(new Error())
    }
    return data
  }, error => {
    cancelLoading()
    if (error.response) {
      let {status, statusText, data} = error.response
      if (data) {
        const {message, code} = data
        if (message) {
          innerMessage('error', message)
        }
        if (code === 401) {
          store.commit('logout', true)
          router.push("/center/login").catch(() => {
          })
        }
      } else {
        innerMessage('error', statusText + ":" + status)
      }
    } else {
      let {code, message} = error
      innerMessage('error', code + ":" + message)
    }
    return Promise.reject(error)
  }
)

const dataHandle = response => {
  return response
}

const innerGet = function () {
  return axiosInstance.get.apply(this, arguments).then(dataHandle)
}

const innerPost = function () {
  return axiosInstance.post.apply(this, arguments).then(dataHandle)
}

const innerDelete = function () {
  return axiosInstance.delete.apply(this, arguments).then(dataHandle)
}

const innerPut = function () {
  return axiosInstance.put.apply(this, arguments).then(dataHandle)
}

export const pureGet = function (url) {
  return innerGet(url)
}

export const pureDelete = function (url) {
  return innerDelete(url)
}

export const get = function (url, params, success, fail) {
  innerGet(url, {
    params: params
  }).then(res => {
    if (success) {
      success(res)
    } else {
      return res
    }
  }).catch(error => {
    if (fail) {
      fail(error)
    }
  })
}

export const post = function (url, data, success, fail) {
  success = success || notThingToDo
  fail = fail || notThingToDo
  innerPost(url, data).then(res => {
    if (success) {
      success(res)
    }
  }).catch(error => {
    if (fail) {
      fail(error)
    }
  })
}

export const del = function (url, params, success, fail) {
  success = success || notThingToDo
  fail = fail || notThingToDo
  innerDelete(url, {
    params: params
  }).then(res => {
    if (success) {
      success(res)
    }
  }).catch(error => {
    if (fail) {
      fail(error)
    }
  })
}

export const put = function (url, data, success, fail) {
  success = success || notThingToDo
  fail = fail || notThingToDo
  return innerPut(url, data).then(res => {
    if (success) {
      success(res)
    }
  }).catch(error => {
    if (fail) {
      fail(error)
    }
  })
}
const setLoading = function () {
  store.commit('setLoading', true)
}
const cancelLoading = function () {
  store.commit('cancelLoading', true)
}
const notThingToDo = function () {
}

import store from '@/store'
import {zh_cn} from "@/common/language/zh-cn";

const lang = {
  'zh-cn': zh_cn
}

export function getLang(word) {
  if (store.getters.getLanguage === 'en') {
    return word
  } else {
    if (lang[store.getters.getLanguage][word] === undefined) {
      console.log(store.getters.getLanguage + ':' + word + ' unset')
      return word
    } else {
      return lang[store.getters.getLanguage][word]
    }
  }
}
